import { useState, useEffect } from "preact/hooks";

interface CardEntry {
  Card: string;
  Amount: number;
}

interface Collection {
  PlayerStartDeck: CardEntry[];
  Shop1Deck: CardEntry[];
}

interface CollectionEditorProps {
  database: string;
}

export default function CollectionEditor({ database }: CollectionEditorProps) {
  const [collection, setCollection] = useState<Collection | null>(null);
  const [availableCards, setAvailableCards] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedDeck, setSelectedDeck] = useState<"PlayerStartDeck" | "Shop1Deck">("PlayerStartDeck");

  const loadCollection = async () => {
    try {
      setLoading(true);
      const response = await fetch(`/api/collection?database=${encodeURIComponent(database)}`);
      if (!response.ok) throw new Error('Failed to load collection');
      
      const data = await response.json();
      setCollection(data.collection);
      setAvailableCards(data.availableCards);
      setError("");
    } catch (err) {
      setError("Failed to load collection data");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCollection();
  }, [database]);

  const saveCollection = async () => {
    if (!collection) return;

    try {
      const response = await fetch('/api/collection', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          database,
          collection
        }),
      });

      if (!response.ok) throw new Error('Failed to save collection');
      setError("");
    } catch (err) {
      setError("Failed to save collection");
      console.error(err);
    }
  };

  const addCard = () => {
    if (!collection || !availableCards.length) return;
    
    const newEntry: CardEntry = {
      Card: availableCards[0],
      Amount: 1
    };

    setCollection({
      ...collection,
      [selectedDeck]: [...collection[selectedDeck], newEntry]
    });
  };

  const removeCard = (index: number) => {
    if (!collection) return;

    setCollection({
      ...collection,
      [selectedDeck]: collection[selectedDeck].filter((_, i) => i !== index)
    });
  };

  const updateCard = (index: number, field: "Card" | "Amount", value: string | number) => {
    if (!collection) return;

    const updatedDeck = [...collection[selectedDeck]];
    updatedDeck[index] = {
      ...updatedDeck[index],
      [field]: value
    };

    setCollection({
      ...collection,
      [selectedDeck]: updatedDeck
    });
  };

  if (loading) {
    return <div>Loading collection...</div>;
  }

  if (!collection) {
    return <div>No collection data found</div>;
  }

  return (
    <div class="p-4 border rounded shadow-sm">
      <h2 class="text-2xl font-bold mb-4">Collection Editor</h2>
      
      {error && (
        <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div class="mb-4">
        <label class="block text-sm font-medium text-gray-700 mb-2">
          Select Deck
        </label>
        <select
          class="w-full p-2 border rounded"
          value={selectedDeck}
          onChange={(e) => setSelectedDeck(e.currentTarget.value as "PlayerStartDeck" | "Shop1Deck")}
        >
          <option value="PlayerStartDeck">Player Start Deck</option>
          <option value="Shop1Deck">Shop 1 Deck</option>
        </select>
      </div>

      <div class="mb-4">
        <button
          class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          onClick={addCard}
        >
          Add Card
        </button>
      </div>

      <div class="space-y-4 max-h-96 overflow-y-auto">
        {collection[selectedDeck].map((entry, index) => (
          <div key={index} class="flex gap-4 items-center border p-4 rounded">
            <select
              class="flex-grow p-2 border rounded"
              value={entry.Card}
              onChange={(e) => updateCard(index, "Card", e.currentTarget.value)}
            >
              {availableCards.map(cardName => (
                <option key={cardName} value={cardName}>
                  {cardName}
                </option>
              ))}
            </select>
            
            <input
              type="number"
              min="1"
              class="w-24 p-2 border rounded"
              value={entry.Amount}
              onChange={(e) => updateCard(index, "Amount", parseInt(e.currentTarget.value) || 1)}
            />
            
            <button
              class="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
              onClick={() => removeCard(index)}
            >
              Remove
            </button>
          </div>
        ))}
      </div>

      <div class="mt-4">
        <button
          class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          onClick={saveCollection}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}